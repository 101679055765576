

export function isDebug() {
    const env = import.meta.env.VITE_REACT_APP_DEBUG;
    return env && env === "true" ;
}
export function getApiUrl() {
    const url = import.meta.env.VITE_REACT_APP_API_URL;
    return url;
}

export function getHelloApiUrl() {
    return `${getApiUrl()}/hello`;
}

export function getUploadApiUrl() {
    return `${getApiUrl()}/upload`;
}

