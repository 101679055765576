
import React, { ChangeEvent, FC, ReactNode, useEffect, useRef, useState } from 'react';

import { useAppSelector, useAppDispatch } from "../../app/hooks"
import './ImageUploader.css';
import { getHealth, generateStaging, selectHealth, selectImageUrl, selectStagedUrl, setInputImageUrl, setStagedUrl, selectisGenerationLoading } from "./imageUploaderSlice";
import { resizeImageToBase64 } from "./utils"

const MAX_WIDTH = 1024;
const MAX_HEIGHT = 1024;
const HEALTH_INTERVAL_IN_SECONDS = 60 * 60 * 1000;
const EXAMPLE_INPUT_URL = "https://storage.googleapis.com/images_to_stage/upload_example.png"
const PROMPT_EXAMPLE = "A stylish living room embracing mid-century modern aesthetics featuring a vintage teak coffee table at its center and a cozy shag rug underfoot creating a warm and inviting atmosphere";

type ImageBoxProps = {
  imageUrl?: string;
  altText?: string;
  children?: ReactNode;
};

type PromptInputProps = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  rows?: number;
};

type FileUploadProps = {

  onFileChange: (value: File) => void;
};

type GeneratedImagePlaceholderProps = {
  isLoading: boolean;
};

const LoadingComponent: React.FC = () => {
  return (
    <div className="loading-container">
      <div></div>
      <div className="loading-dots">
        <div className="loading-dot-1">.</div>
        <div className="loading-dot-2">.</div>
        <div className="loading-dot-3">.</div>
      </div>
    </ div>
  );
};

const GeneratedImagePlaceholder: React.FC<GeneratedImagePlaceholderProps> = (props: GeneratedImagePlaceholderProps) => {
  return (
    <div className="generated-placeholder">
      {!props.isLoading && "Generated Image will be shown here"}
      {props.isLoading && <LoadingComponent />}
    </div>
  );
};

const ImageBox: React.FC<ImageBoxProps> = (props: ImageBoxProps) => {
  return (
    <div className="image-box">
      {props.imageUrl && <img src={props.imageUrl!} alt={props.altText} />}
      {!props.imageUrl && props.children}
    </div>
  );
};


const PromptInput: React.FC<PromptInputProps> = ({
  value,
  onChange,
  placeholder = '',
  rows = 5,
}) => {
  return (
    <textarea
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      rows={rows}
      className="textarea"
    />
  );
};


const FileUploadComponent: React.FC<FileUploadProps> = (props: FileUploadProps) => {

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      props.onFileChange(file);
    }
  };

  return (
    <div className='file-upload'>
      <input type="file" id="img-file-upload" accept="image/*" onChange={handleFileChange} />
      <label htmlFor="img-file-upload">Upload an Image</label>
    </div>
  );
};

export function ImageUploader() {
  const dispatch = useAppDispatch()

  const healthStatus = useAppSelector(selectHealth)
  const imageUrl = useAppSelector(selectImageUrl)
  const stagedUrl = useAppSelector(selectStagedUrl)
  const isGenerationLoading = useAppSelector(selectisGenerationLoading)

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [textPrompt, setTextPrompt] = useState('');

  useEffect(() => {
    dispatch(getHealth());
    // Dispatch the API call to fetch the count
    const interval = setInterval(() => {
      dispatch(getHealth());
      console.log("Attempting getHealthAsync")

    }, HEALTH_INTERVAL_IN_SECONDS);

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, [dispatch]);


  const onFileChange = async (file: File) => {
    setSelectedFile(file);
    const image64 = await resizeImageToBase64(file, MAX_WIDTH, MAX_HEIGHT);
    dispatch(setInputImageUrl(image64))
    dispatch(setStagedUrl(undefined))
  };

  const handleGenerateClick = async () => {
    console.log('Generate clicked:', textPrompt);
    if (!selectedFile || !textPrompt) {
      alert("Please select an image file and enter prompt text");
      return;
    }
    if (!textPrompt) {
      alert("Please enter prompt text");
      return;
    }
    const image64 = await resizeImageToBase64(selectedFile, MAX_WIDTH, MAX_HEIGHT);
    dispatch(generateStaging(image64, textPrompt));
  };

  return (
    <div>
      <div className="image-container">
        <ImageBox imageUrl={imageUrl} children={<FileUploadComponent onFileChange={onFileChange} />} />
        <ImageBox imageUrl={stagedUrl} children={<GeneratedImagePlaceholder isLoading={isGenerationLoading} />} />
      </div>
      <PromptInput value={textPrompt} onChange={(str) => { setTextPrompt(str) }} placeholder={PROMPT_EXAMPLE} />
      <button disabled={isGenerationLoading} type="submit" className={isGenerationLoading ? "submit-button disabled" : "submit-button"} onClick={handleGenerateClick}>
        {isGenerationLoading ? "Generating..." : "Stage!"}
      </button>
      <div className="server-health"> Server Health: {healthStatus ? '🟢' : '🔴'}</div>
    </div>
  );
}