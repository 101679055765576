import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState, AppThunk } from "../../app/store"
import { getHealthStatus } from "../../api/health"
import { generateImage } from "../../api/upload"
import { toast } from "react-toastify";

export interface ImageUploadState {
  imageUrl?: string,
  stagedUrl?: string,
  healthy: boolean,
  isGenerationLoading: boolean,
  }

  
const initialState: ImageUploadState = {
  imageUrl: undefined,
  stagedUrl: undefined,
  healthy: false,
  isGenerationLoading: false,
  }
  

  export const generateStagingAsync = createAsyncThunk(
    "upload/generate_staging",
    async ( gen: {image64: string, promptInput: string}) => {
      const generation_response = await generateImage(gen.image64, gen.promptInput)
      console.log("Dispatching generateStagingAsync", generation_response)
      return generation_response
    },
  )

  export const getHealthAsync = createAsyncThunk(
    "upload/health",
    async () => {
      const health_status = await getHealthStatus()
      return health_status
    },
  )

export const imageUploaderSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    setInputImageUrl: (state, action: PayloadAction<string>) => {
      state.imageUrl = action.payload;
    },
    setStagedUrl: (state, action: PayloadAction<string | undefined>) => {
      state.stagedUrl = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getHealthAsync.pending, (state) => {
    })
    .addCase(getHealthAsync.fulfilled, (state, action) => {
      state.healthy = true;
    })
    .addCase(getHealthAsync.rejected, (state) => {
      state.healthy = false;
    })
    .addCase(generateStagingAsync.pending, (state) => {
      state.isGenerationLoading = true;
      toast.info("Generating Image....")
    })
    .addCase(generateStagingAsync.fulfilled, (state, action) => {
      toast.info("Generating Image Succesfully", action.payload)
      state.imageUrl = action.payload.image_path
      state.stagedUrl = action.payload.staged_path;
      state.isGenerationLoading = false;
    })
    .addCase(generateStagingAsync.rejected, (state) => {
      toast.info("Failed Generation.")
    })
  },
})

  
export const { setInputImageUrl, setStagedUrl } = imageUploaderSlice.actions

export const selectHealth = (state: RootState) => state.imageUploader.healthy
export const selectImageUrl = (state: RootState) => state.imageUploader.imageUrl
export const selectStagedUrl = (state: RootState) => state.imageUploader.stagedUrl
export const selectisGenerationLoading = (state: RootState) => state.imageUploader.isGenerationLoading


export const getHealth =
  (): AppThunk =>
  (dispatch, getState) => {
    const currentHealth = selectHealth(getState());
    return dispatch(getHealthAsync());
  }

  export const generateStaging =
  (image64: string, promptInput: string): AppThunk =>
  (dispatch) => {
      return dispatch(generateStagingAsync({image64: image64, promptInput: promptInput}));
  }

  export default imageUploaderSlice.reducer
