

import {
    getUploadApiUrl,
  } from "./environment"
  
export async function generateImage(image64: string, promptInput: string) {
    console.log("Calling generateImage api with prompt: ", promptInput)
 
    const response = await fetch(getUploadApiUrl(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({image64: image64, prompt: promptInput}),
      });
    
      const data = await response.json();
      return data;
}
