
export function resizeImageToBase64(file: File, maxWidth: number, maxHeight: number): Promise<string> {

    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function (event) {
        const img = new Image();
        img.src = event.target?.result as string;

        img.onload = function () {
          try {
            const canvas = document.createElement('canvas');
            let width = img.width;
            let height = img.height;

            // Calculate new dimensions while preserving aspect ratio
            if (width > height) {
              if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
              }
            } else {
              if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
              }
            }

            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext('2d');
            if (ctx) {
              ctx.drawImage(img, 0, 0, width, height);
              const resizedBase64 = canvas.toDataURL('image/png'); // Change image type if needed
              resolve(resizedBase64);
            } else {
              throw new Error("Unable to resize image. Canvas context is not supported.");
            }
          } catch (error) {
            reject(error);
          }
        };

        img.onerror = function () {
          reject(new Error("Failed to load image."));
        };
      };

      reader.onerror = function () {
        reject(new Error("Failed to read file."));
      };

      reader.readAsDataURL(file);
    });
  }