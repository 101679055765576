import {
  getHelloApiUrl,
} from "./environment"


export async function getHealthStatus(): Promise<void> {
    const response = await fetch(getHelloApiUrl(), {
        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      });
    await response;
}


