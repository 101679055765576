import logo from "./logo.svg"
import { Counter } from "./features/counter/Counter"
import { ImageUploader } from "./features/image_uploader/ImageUploader"
import "./App.css"

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <ImageUploader />
        {/* <Counter /> */}
      </header>
    </div>
  )
}

export default App
